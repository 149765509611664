import './src/styles/tailwind.css';

export function onRouteUpdate({ location }) {
  if (!window.gtag) {
    return;
  }

  window.gtag('event', 'page_view', {
    page_title: document.title,
    page_location: location.href,
    page_path: location.pathname,
  });
}
